<template>
  <div class="d-flex dropdownWrapper" :style="{width: width}">
    <div
      class="dropdownLabel content-center"
      style="width: 40%"
      v-if="labelText !== ''"
    >
      <div class="font-medium w-100 d-flex justify-content-center">
        {{ labelText }}
      </div>
      <div class="line"></div>
    </div>
    <div
      class="custom-select"
      :tabindex="tabindex"
      @blur="open = false"
      :style="labelText !== '' ? 'width: 60%' : 'width:100%'"
    >
      <div
        class="selected font-medium"
        :class="{ open: open, default: selected === placeHolder }"
        @click="open = !open"
      >
        {{ selected }}
      </div>
      <div
        class="items"
        :class="{ selectHide: !open }"
        :style="options.length <= 6 ? 'height: auto;' : 'height: 300px'"
      >
        <div class="item d-none">{{ placeHolder }}</div>
        <div
          class="item"
          v-for="(option, i) of options"
          :key="i"
          :class="{
            'disable-option': option.id === selectedObj.id,
            disabled: option.disable,
          }"
          @click="
            selected = option.text;
            selectedObj = option;
            open = false;
            $emit('input', option);
          "
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "w-white-dropdown",
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    placeHolder: {
      type: String,
      required: false,
      default: "Select",
    },
    labelText: {
      type: String,
      required: false,
      default: "",
    },
    selectedOption: {
      required: false,
      default: () => {},
    },
    width: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: '',
      open: false,
      selectedObj: {},
    };
  },
  created() {
    if (this.selectedOption !== null && this.selectedOption !== undefined) {
      this.selectedObj = this.selectedOption;
      this.selected = this.selectedOption.text;
    } else {
      this.selectedObj = {};
      this.selected = this.placeHolder
    }
  },
  beforeUpdate(){
    if (this.selectedOption !== null && this.selectedOption !== undefined && this.selectedOption !=='') {
      this.selectedObj = this.selectedOption;
      this.selected = this.selectedOption.text;
    } else {
      this.selectedObj = {};
      this.selected = this.placeHolder
    }
  },
  mounted() {
    this.selected = this.placeHolder
    this.$emit("input", this.selectedObj);
  },
};
</script>
<style scoped>
.dropdownLabel {
  color: #727988;
  height: 55px;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
.default {
  font-family: ProximaNovaBold !important;
}
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  height: 60px;
  line-height: 55px;
  border-radius: 0px;
  border: 0px;
  appearance: auto;
  padding: 0px;
  z-index: 10;
}
.custom-select:focus {
  box-shadow: none;
}
.disable-option {
  color: #313846;
  font-family: ProximaNovaBold !important;
  background-color: #eff5ff;
}
.selected {
  background-color: #fff;
  border: 0px solid #858586;
  color: #222a37;
  font-family: ProximaNovaBold;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
}

.selected.open {
  border: 0px solid;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #050505 transparent transparent transparent;
}

.items {
  color: #313846;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  right: 0;
  font-size: medium;
  z-index: 99;
}
.items::-webkit-scrollbar {
  background-color: #eff5ff;
  width: 10px;
  height: 7px;
}
.items::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.items::-webkit-scrollbar-thumb {
  background: #313846;
  border: 0px none #ffffff;
  border-radius: 10px;
}
.item {
  color: #313846;
  padding-left: 11px;
  cursor: pointer;
  user-select: none;
  font-family: ProximaNovaRegular;
}
.item.disabled {
  pointer-events: none;
  font-family: ProximaNovaRegular;
  color: lightgray;
}
.item:hover {
  background-color: #eff5ff;
  font-family: ProximaNovaBold;
}

.selectHide {
  display: none;
}
.dropdownWrapper {
  width: 260px;
  background: #fff;
  box-shadow: 0 0 8px 0 #c9ced5;
  z-index: 90;
}
</style>
