import axios from "axios";
// import { store } from "../../store/store.js";

// const BASE_HEADERS = "";
const validStatuses = [200, 201, 400];
const APP_SERVER_URL = process.env.VUE_APP_SERVER_URL;
const API_URL = process.env.VUE_APP_AI_URL;
// const API_CONTENT_URL = "";

export class HotifyApiHelper {
    constructor() {}

    get(uri) {
        return axios
            .get(`${API_URL}` + uri, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    downloadFile(filename, uri) {
        axios({
            url: `${API_URL}` + uri,
            method: "POST",
            responseType: "blob",
            headers: this.getHeaders(),
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename + ".csv");
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }
    post(uri, data) {
            return axios
                .post(`${API_URL}` + uri, data, {
                    headers: this.getHeaders(),
                    withCredentials: false,
                })
                .then(this.checkResponse)
                .catch(this.handleError);
        }
        // put(){
        //     return axios.put()
        // }
    options(uri) {
        return axios
            .options(`${API_URL}` + uri, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.checkResponse);
    }
    googleOauth(uri) {
        return axios
            .get(uri, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    patch(uri, data) {
        return axios
            .patch(`${API_URL}` + uri, data, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    delete(uri, data) {
        return axios
            .delete(`${API_URL}` + uri + "/" + data, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    /*
     * Wraps axios and provides
     * more convenient post method
     * calls with payload data
     */
    login(uri, data) {
        return axios
            .post(`${API_URL}` + uri, data, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then((response) => {
                // store.commit('setToken', response.headers['token'] ? response.headers['token'] : '');
                // sessionStorage.setItem(
                //     "Token",
                //     response.accessToken
                // );
                return response;
            })
            .catch(this.handleError);
    }

    logout(uri) {
        // store.commit('setToken', '');
        return axios
            .post(
                `${APP_SERVER_URL}` + uri, {}, {
                    headers: this.getHeaders(),
                    withCredentials: false,
                }
            )
            .then(() => {
                sessionStorage.removeItem("Token");
                window.location.href = "/";
            })
            .catch(this.handleError);
    }

    /*
     * Wraps error responses from the API
     * and returns `Promise.reject` with error
     */
    checkResponse(response) {
        if (validStatuses.includes(response.status)) {
            return response.data;
        }
        // If not authorized then reset token
        // and redirect to login page
        if (response.status === 401) {
            return Promise.reject(new Error("USER_ANONYMOUS"));
        } else if (response.status === 403) {
            sessionStorage.removeItem("Token");
            window.location.href = "/";
            return Promise.reject(new Error("USER_ANONYMOUS"));
        } else {
            //store.commit("showApiError", true);
            let err = new Error(response.statusText);
            err.response = response;
            return Promise.reject(err);
        }
    }
    uploadFile(uri, data) {
        return axios
            .post(`${API_URL}` + uri, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: sessionStorage.getItem("Token"),
                },
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    handleError(error) {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    // sessionStorage.removeItem("Token");
                    // window.location.href = '/app/'
                    // window.location.href = `${LOGOUT_URL}`;
                    return Promise.reject(new Error("Credential_False"));
                case 403:
                    sessionStorage.removeItem("Token");
                    window.location.href = "/";
                    // window.location.href = `${LOGOUT_URL}`;
                    return Promise.reject(new Error("Credential_False"));
                case 400:
                    return Promise.reject(error.response.data);
                case 404:
                    return Promise.reject(error.response);
                case 406:
                    return Promise.reject(new Error("User is not active"));
                default:
                    //store.commit("showApiError", true);
                    // let err = new Error(error.response);
                    return Promise.reject(error);
            }
        } else {
            // store.commit("showApiError", true);
            // let err = new Error(error);
            return Promise.reject("Something went wrong, Please try later");
        }
    }

    getHeaders() {
        var token = sessionStorage.getItem("Token");
        return {
            Authorization: token,
        };
    }
}