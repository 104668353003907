<template>
  <div class="wrapper-statistics">
    <div v-for="(chart, i) in chartSummary" :key="i" class="item">
      <div class="item-img" :class="chart.className">
        <img :src="getImage(chart.image)" alt="" height="25px" width="25px" />
      </div>

      <div class="item-text">
        <div class="item-text__title">{{ chart.name }}</div>
        <div class="item-text__subtitle">{{ chart.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartOverview",
  props: {
    chartSummary: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getImage(image) {
      try {
        return require("@/assets/Icons/" + image);
      } catch {
        return require("@/assets/Icons/pie-chart-2-fill.svg");
      }
    },
  },
};
</script>

<style scoped>
.wrapper-statistics {
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  overflow: auto;
  justify-content: space-between;
}

.item {
  display: flex;
  position: relative;
  width: 100%;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 3px;
  background: #e8edf5;
  right: 6px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.blue-pastel {
  background: #40c4ff;
}
.green-pastel {
  background: #64ffda;
}
.pink-pastel {
  background: #ff80ab;
}
.purple-pastel {
  background: #ea80fc;
}
.red-pastel {
  background: #ff8a80;
}
.yellow-pastel {
  background: #ffd740;
}
.red-pastel {
  background: #ff5252;
}
.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
}

.item-text__title {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .item {
    width: 100%;
    margin-right: 15px;
  }
  .wrapper-statistics {
    overflow: auto;
  }
  .border_top_divider_last::after {
    top: 40.2%;
  }
}
@media screen and (max-width: 768px) {
  .item {
    width: 100%;
    margin-right: 15px;
  }
  .wrapper-statistics {
    overflow: auto;
  }
}
@media screen and (max-width: 1024px) {
  .item {
    width: 100%;
    margin-right: 20px;
  }
  .wrapper-statistics {
    overflow: auto;
  }
}
</style>
