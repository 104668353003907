import { HotifyApiHelper } from "./helper/HotifyApiHelper";
const hotifyApiHelper = new HotifyApiHelper();

export class AimoUnileverServices {
    constructor() {}
    getAllModel(modelType) {
        const uri = `process/v1/uniliverData?keyword=modelsummary&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getOverallAttribution(modelType) {
        const uri = `process/v1/uniliverData?keyword=overallattribution&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getPrintData(modelType) {
        const uri = `process/v1/uniliverData?keyword=print&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getDigitalData(modelType){
        const uri = `process/v1/uniliverData?keyword=digital&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getTVData(modelType) {
        const uri = `process/v1/uniliverData?keyword=tv&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getDigitalPerformanceData(modelType) {
        const uri = `process/v1/uniliverData?keyword=digitalperformance&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getExternalData(modelType) {
        const uri = `process/v1/uniliverData?keyword=core&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getInternalData(modelType) {
        const uri = `process/v1/uniliverData?keyword=advertising&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }
    getInvsVsRevenueData(modelType) {
        const uri = `process/v1/uniliverData?keyword=investmentvsrevenue&folder=${modelType}`;
        return hotifyApiHelper.get(uri);
    }   
}